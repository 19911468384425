import React, { useEffect, useState } from "react";
import { useModal } from "../../hooks/useModal";
import styles from "./black-friday-promo-popup.module.scss";
import { Save20Percent } from "./subtitle";
import Link from "next/link";
import { CloseButtonIcon } from "./CloseButtonIcon";

import { getCookie, setCookie } from "@/lib/cookies";
import { useConsentCookie } from "util/useConsentCookie";
import Head from "next/head";
import { useSessionStorage } from "util/useSessionStorage";

function BlackFridayPromoPopup() {
  const [sessionState, setSessionState] = useSessionStorage(
    "blackFridayPopupClosed",
    false
  );
  const { getConsentCookieData } = useConsentCookie();
  const [hasOpened, setHasOpened] = useState(false);

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (sessionState) return;
      const cookieName = "blackFridayPopupClosed";
      const cookieValue = getCookie(cookieName);

      if (!cookieValue) {
        open();
      }
      setHasOpened(true);
    }
  }, []);

  const handleClose =
    (close: () => any): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      const consentCookie = getConsentCookieData();
      setSessionState(true);
      if (consentCookie && consentCookie.preferences && hasOpened) {
        setCookie("blackFridayPopupClosed", "true", 15);
      }
      close();
    };

  const { modal, open, close } = useModal({
    closeButton: false,
    content: ({ close }) => {
      return (
        <div className={styles.BlackFridayPromoPopup}>
          <Head>
            <link
              rel="preload"
              href="/assets/images/black-friday-promo-popup-background.svg"
              as="image"
            />
          </Head>
          <button className={styles.closeButton} onClick={handleClose(close)}>
            <CloseButtonIcon />
          </button>
          <div className={styles.title}>
            November Deals Month
            <div className={styles.subTitle}>
              <Save20Percent />
            </div>
          </div>
          <button className={styles.button}>
            <Link href="/black-friday-promo-november-2024">
              <a>Shop Now</a>
            </Link>
          </button>
          <div className={styles.footerText}>
            Exclusive Black Friday deals all month long
            <br />
            Save on accurate, efficient audiometry solutions
          </div>
        </div>
      );
    },
  });

  return modal;
}

export default BlackFridayPromoPopup;
