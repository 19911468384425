export const CloseButtonIcon = ({ className }: { className?: string }) => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_d_2169_395)">
      <circle cx="27.5" cy="23.5" r="23.5" fill="#0096A7" />
      <rect
        x="19.7218"
        y="12.1863"
        width="27"
        height="5"
        rx="2.5"
        transform="rotate(45 19.7218 12.1863)"
        fill="white"
      />
      <rect
        x="16.1863"
        y="31.2782"
        width="27"
        height="5"
        rx="2.5"
        transform="rotate(-45 16.1863 31.2782)"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2169_395"
        x="0"
        y="0"
        width="55"
        height="55"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2169_395"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2169_395"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
