import { getCookie } from "@/lib/cookies";
import { useEffect, useState } from "react";

type ConsentCookieData = {
  stamp: string;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
  method: string;
  ver: number;
  utc: number;
  region: string;
};

export const useConsentCookie = (): {
  consentCookieData: ConsentCookieData | null;
  getConsentCookieData: () => ConsentCookieData;
} => {
  const [consentCookieData, setConsentCookieData] =
    useState<ConsentCookieData | null>(null);

  const getConsentCookieData = () => {
    const consentCookie = getCookie("CookieConsent");
    console.log("consentCookie", consentCookie);
    const decodedConsentCookie = decodeURIComponent(consentCookie)
      .replace(/([a-zA-Z0-9_]+)\s*:/g, '"$1":')
      .replace(/'([^']*)'/g, '"$1"');
    console.log("decodedConsentCookie", decodedConsentCookie);
    const consentCookieParsed =
      consentCookie && JSON.parse(decodedConsentCookie);
    setConsentCookieData(consentCookieParsed);
    return consentCookieParsed;
  };

  return { consentCookieData, getConsentCookieData };

};
